import React from "react";
import { IntlProvider } from "react-intl";
import { PageProvider } from "./src/contexts/page";
import fr from "./src/locales/fr.json";
import en from "./src/locales/en.json";

const messages = { fr, en };

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;

  return (
    <IntlProvider
      locale={pageContext.locale}
      defaultLocale="fr"
      messages={messages[pageContext.locale]}
    >
      <PageProvider value={pageContext}>{element}</PageProvider>
    </IntlProvider>
  );
};
